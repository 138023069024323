<template>
  <div
    class="create-account-modal"
    data-t="create-account-modal-vhtr"
    :class="{ mobile: isMobile }"
  >
    <div v-if="isMobile" class="close-wrapper">
      <div class="close-icon">
        <StIcon name="cross-large" size="20" @click="handleClose" />
      </div>
    </div>
    <StIcon class="create-account-icon" name="wallet-solid" size="48" />
    <h3 class="create-account-title">
      {{ t('createAccountModal.title') }}
      <StTooltip placement="top">
        <template #activator>
          <StTooltipButton :size="isMobile ? 's' : 'm'" />
        </template>
        <p class="title-tooltip">
          {{ t('createAccountModal.tooltipText') }}
        </p>
      </StTooltip>
    </h3>

    <p class="create-account-text">{{ t('createAccountModal.text') }}</p>
    <StButton
      :label="t('createAccountModal.button')"
      type="primary"
      :size="isMobile ? 'l' : 'xl'"
      class="create-account-button"
      data-t="create-account-button-jtcn"
      replace
      block
      :to="{
        query: { ...route.query, modal: 'userVerification', target: 'deposit' },
      }"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { isMobile } = usePlatform()
const router = useRouter()
const route = useRoute()

function handleClose() {
  router.replace({ query: { ...route.query, modal: undefined } })
}
</script>

<style scoped>
.create-account-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 480px;
  padding: var(--spacing-500) var(--spacing-600);

  background-image: url('./images/bg.jpg');
  background-position: top center;
  background-size: cover;
  border-radius: var(--border-radius-200);

  :deep(.wrapper) {
    display: inline-block;
    margin-left: var(--spacing-025);
    vertical-align: 3px;
  }
}

.create-account-title {
  margin: 0;
  margin-bottom: var(--spacing-100);
  font: var(--desktop-text-xl-semibold);
  text-align: center;
}

.create-account-icon {
  margin-bottom: var(--spacing-300);
}

.create-account-text {
  margin: 0;
  margin-bottom: var(--spacing-300);

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
  text-align: center;
}

.title-tooltip {
  margin: 0;
  padding: var(--spacing-100) var(--spacing-150);
  font: var(--desktop-text-xs-medium);
}

.mobile {
  &.create-account-modal {
    position: relative;
    max-width: 100%;
    padding: var(--spacing-450) var(--spacing-200) var(--spacing-200);
    border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
  }

  .close-wrapper {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 0;

    display: flex;
    justify-content: flex-end;

    width: 100%;
    padding: var(--spacing-050);

    .close-icon {
      padding: var(--spacing-125);
    }
  }

  .create-account-icon {
    margin-bottom: var(--spacing-250);
  }

  .create-account-title {
    margin-bottom: var(--spacing-050);
    font: var(--mobile-title-1-semibold);
    text-wrap: balance;

    @media screen and (width <= 400px) {
      max-width: 300px;
    }
  }

  .create-account-text {
    font: var(--mobile-text-content-regular);
  }
}
</style>
